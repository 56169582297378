import "./intro.css"

import { FaAward } from "react-icons/fa"
import React from "react"
import { VscFolderLibrary } from "react-icons/vsc"
import img from "../../assets/profile.jpeg"

const Intro = () => {
  return (
    <section id="about">
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={img} alt="Rufat Mammadyarov" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>10+ years</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Education</h5>
              <small>BA/MA in Computer Science</small>
            </article>
          </div>
          <p>
            Experienced and versatile, I am a web developer with over a decade
            of expertise in both front-end and back-end development. I have
            successfully managed and completed numerous projects using
            WordPress, Drupal, Django, and Python. As a team leader, I have
            guided and motivated a group of 10+ developers, ensuring outstanding
            results on various WordPress endeavors. Additionally, I have
            mastered React and Next.js, enhancing user interfaces and
            maintaining over 200 websites, ranging from small businesses to
            large e-commerce platforms. With a meticulous approach and a passion
            for delivering high-quality solutions, I am dedicated to crafting
            captivating online experiences tailored to your needs. Let's
            collaborate and create something extraordinary in the digital realm.
          </p>
          <a href="#contact" className="btn btn-primary">
            Reach out
          </a>
        </div>
      </div>
    </section>
  )
}

export default Intro
