import React from "react"
import CTA from "./CTA"
import HeaderSocials from "./HeaderSocials"
import "./header.css"

const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h1>Rufat Mammadyarov</h1>
        <h5 className="text-light">
          Director of Technology | Senior Developer | Web Developer | Web
          Development | Technology | Project Management
        </h5>
        <CTA />

        <div className="footer__socials">
          <a
            href="https://www.linkedin.com/in/rufat-mammadyarov-9a452011/"
            target="_blank"
            rel="noreferrer"
          ></a>
        </div>
      </div>
    </header>
  )
}

export default Header
