import React from "react"
import { BsLinkedin } from "react-icons/bs"
import { Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import "./testimonials.css"

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      link: "https://www.linkedin.com/in/calebzahnd/",
      name: "Caleb Zhand",
      role: "Director of Web Development",
      test: "I had the privilege of working under Rufat's leadership for 4 years. During that time, I found Rufat to be a priceless wealth of experience and wisdom and a person who is always eager to share with others. He was not only a mentor to me but also a friend, always willing to help out with any questions or problems I had. He is intelligent, innovative, and highly devoted to his work. I watched him consistently work long hours to ensure that our projects were completed to the highest standard. He is always looking for ways to improve processes and increase efficiency. His dedication to his work was inspiring to me and to the rest of our team. I have no doubt that Rufat would be an asset to any company he works for. He is a true professional with a passion for technology and a commitment to excellence. I highly recommend him and would welcome the opportunity to work alongside him again without hesitation.",
    },
    {
      id: 2,
      link: "https://www.linkedin.com/in/stormrockwell/",
      name: "Storm Rockwell",
      role: "Senior Full Stack Developer",
      test: "Rufat is an exceptional leader and built the 829 development team from the ground up. Having worked alongside Rufat for two years, I can say that he genuinely cares about his team, personally and professionally. He allowed me to grow my skills in a challenging yet rewarding environment. His knowledge feels limitless, and he always makes time for me when needed. It was a pleasure working with Rufat, and I look forward to crossing paths with him in the future.",
    },
    {
      id: 3,
      link: "https://www.linkedin.com/in/nwredmond/",
      name: "Nicole Redmond",
      role: "Lead Product Developer",
      test: "Rufat is a great manager, incredibly hard worker, brilliant developer, and an all-around wonderful person.I've become such a better developer working with him as my manager. He has a vast and in-depth technical knowledge and is always willing to share and teach. His combination of intelligence and modest ego makes him an effective leader of dev teams. He treats other developers as equals and values contributions from anyone regardless of experience.",
    },
    {
      id: 4,
      link: "https://www.linkedin.com/in/peterthomasross/",
      name: "Peter Ross",
      role: "Founding Partner at 829 Studios",
      test: "During his tenure at 829 Studios, Rufat played a pivotal role in building and nurturing our in-house development team. He was instrumental in establishing the team from scratch, showcasing his remarkable talent for identifying and recruiting top-tier professionals. Rufat's dedication to cultivating talent was evident through his meticulous training programs, which equipped the team with the necessary skills to excel in their roles. Under his guidance, the team flourished and became a driving force behind our company's success.",
    },
  ]
  return (
    <section id="testmonials">
      <h5>Feedback from my peers</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {testimonials.map((test) => (
          <SwiperSlide className="testimonial" key={test.id}>
            <div className="client__avatar">
              <a href={test.link}>
                <BsLinkedin />
              </a>
            </div>
            <h5 className="client__name">{test.name}</h5>
            <small className="client__review">{test.test}</small>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default Testimonials
